<template>
    <div class="employer">
        <front-header :positionShow="true" :chooseTitle="'employer'"></front-header>
        <div class="employer-box">
            <accordion v-for="(item,index) in A_accordion" :titleType="1" :title1="item.title1" :title2="item.title2"
                       :backColor="'background:rgba(241,248,255,1);border-top:3px solid #0053B8'"
                       :openShow="item.openShow?true:false"
                       :key="index">
                <div slot="child">
                    <accordion2 slot="child" :childData="item.child"></accordion2>
                </div>
            </accordion>
            <div class="employer-box-bottom">
                更多险种即将到来，敬请期待...
            </div>
        </div>
        <front-bottom></front-bottom>
    </div>

</template>

<script>
    import accordion from "../../components/accordion";
    import accordion2 from "../../components/accordion2";
    import frontHeader from "../../components/front/frontHeader";
    import frontBottom from "../../components/front/frontBottom";
    export default {
        name: "employerInsurance",
        components: {
            accordion,
            accordion2,
            frontHeader,
            frontBottom
        },
        data() {
            return {
                A_accordion:[]
            }
        },
        created() {
            this.pro_num = this.$route.query.pro_num
        },
        mounted() {
            console.log(this.pro_num)
            this.getProductList(this.pro_num)
        },
        methods: {
            // 加载列表数据
            getProductList:function(pro_num){
                let that=this;
                let loading=that.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                let _data={}
                if(pro_num){
                    _data.pro_num=pro_num
                }
                that.axios({
                    url: this.$store.state.baseUrl + '/api/product/newproduct/getProductList',
                    method: 'POST',
                    params:_data
                }).then(function(res) {
                    console.log(res)
                    loading.close();
                    if(res.data.code==200){
                        that.A_accordion.push(res.data.data)
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            },
        }
    }
</script>

<style lang="scss">
    @media screen and (max-width: 900px) {
        .employer-box {
            margin-left: 0!important;
            width: 100vw!important;
        }
    }
    .employer {
        min-height: 100vh;
        position: relative;
        // padding-bottom: 391px;
        box-sizing: border-box;
        padding-top: 66px;
    }

    .employer-box {
        width: 70%;
        margin-left: 15%;
        margin-bottom: 80px;
        & .el-collapse-item__header {
            background: #F8F8F8 !important;
            padding: 0 15px;
        }

        .employer-box-bottom {
            height: 94px;
            line-height: 94px;
            margin-top: 32px;
            width: 100%;
            background-size: cover;
            background: url('../../image/employerInsurance/back.png');
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            text-shadow: 0px 2px 0px rgba(22, 59, 112, 1);
        }
    }
</style>
