<template>
    <div class="accordion-box"
         :style="((open&&titleType===1)?'border:1px solid rgba(236,236,239,1);':'')+(titleType===1?'margin-top: 41px;':'margin-top: 15px')">
        <div @click="contentShow" :style="backColor"
             :class="'accordion-title ' + (titleType===1?'accordion-title1':'accordion-title2')">
            <div><span>{{title1}}</span></div>
            <div><span>{{title2}}</span></div>
            <div class="iconfont">{{open?'&#xe658;':'&#xe61f;'}}</div>
        </div>
        <div class="accordion-content"
             :style="'height:'+ contentHeight+';'+ (titleType===1?'border:border:1px solid rgba(236,236,239,1);':'')">
            <slot name="child"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "accordion",
        components: {
            // AccordionTitle
        },
        data() {
            return {
                contentHeight: 0,
                open: false
            }
        },
        props: {
            titleType: {
                default: 1
            },
            title1: {
                default: ''
            },
            title2: {
                default: ''
            },
            backColor: {
                default: 'background:rgba(248,248,248,1);'
            },
            openShow: {
                default: false
            }
        },
        mounted() {
            this.open = this.openShow
            if (this.open) {
                this.contentHeight = 'auto'
            }
        },
        methods: {
            contentShow() {
                if (this.open) {
                    this.contentHeight = '0'
                    this.open = false
                } else {
                    this.contentHeight = 'auto'
                    this.open = true
                }

            }
        }
    }
</script>

<style scoped lang="scss">
    .accordion-box {
        width: 100%;
        .accordion-title {
            padding: 15px 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .accordion-title1 {
            display: flex;

            & > div {
                display: table-cell;
                justify-content: center;
                align-items: baseline;
                vertical-align: middle;
            }

            & > div:nth-child(1) {
                width: 140px;
                text-align: center;
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 500;
                color: rgba(22, 84, 178, 1);
                line-height: 30px;

            }

            & > div:nth-child(2) {
                width: calc(100% - 150px);
                text-align: left;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(102, 102, 102, 1);
                line-height: 24px;
            }

            & > div:nth-child(3) {
                width: 50px;
                text-align: center;
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: 500;
                color: rgba(22, 84, 178, 1);
                line-height: 30px;
            }
        }

        .accordion-title2 {
            padding: 15px 20px;
            flex-wrap: wrap;
            position: relative;

            & > div:nth-child(1) {
                width: 100%;
                text-align: left;
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                line-height: 30px;
            }

            & > div:nth-child(2) {
                width: 100%;
                text-align: left;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(102, 102, 102, 1);
                line-height: 24px;
            }

            & > div:nth-child(3) {
                position: absolute;
                right: 20px;
                top: 0;
                font-size: 18px;
                line-height: 81px;
            }
        }

        .accordion-content {
            height: 0px;
            overflow: hidden;
            transition: height 1s;
        }
    }
</style>
