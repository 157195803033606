<template>
    <div>
        <accordion v-for="(item,index) in childData" :titleType="2" :title1="item.title1"
                   :title2="item.title2"
                   :openShow="item.openShow?true:false"
                   :key="index">
            <div slot="child" class="accordion2-content-box">
                <div class="accordion2-content-box-content" v-for="(item1,index1) in item.children" :key="index1">
                    <div>{{item1.title1}}</div>
                    <div>
                        <div>
                            <div>{{item1.title2}}</div>
                            <div>{{item1.content}}</div>
                        </div>
                        <img :src="baseUrl+item1.pcimg" alt="">
                    </div>
                    <div>
                        <span>￥</span>
                        <span>{{item1.money}}</span>
                        <span>起</span>
                    </div>
                    <div>
                        <div v-for="(itemData,index2) in item1.text" :key="index2">
                            <div>{{itemData[0]}}</div>
                            <div>{{itemData[1]}}</div>
                        </div>
                    </div>
                    <el-button style="width: 210px;height: 50px;font-size: 24px;margin-top: 31px"
                               @click="routerTo(item1.pro_num,item1.work_id)" type="primary">了解详情
                    </el-button>
                </div>
            </div>

        </accordion>
    </div>
</template>

<script>
    import accordion from "./accordion";

    export default {
        name: "accordionTitle",
        components: {
            accordion
        },
        props: {
            childData: {
                default: []
            }
        },
        data() {
            return {
                baseUrl: this.$store.state.baseUrl
            }
        },
        methods: {
            routerTo(pro_num, work_id) {
               let routeUrl = this.$router.resolve({
                    path: '/detail',
                    query: {
                        pro_num: pro_num,
                        work_id: work_id
                    }
                })
                window.open(routeUrl.href, '_blank');
            }
        }
    }
</script>

<style scoped lang="scss">
    @media screen and (max-width: 900px) {
        .accordion2-content-box-content {
            width: calc(100vw - 20px) !important;
        }
        .accordion2-content-box {
            padding: 10px !important;
        }
    }

    .accordion2-content-box {
        width: 100%;
        display: flex;
        height: auto;
        justify-content: flex-start;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 30px;
        box-sizing: border-box;
    }

    .accordion2-content-box-content {
        box-sizing: border-box;
        width: 376px;
        height: 420px;
        padding: 19px;
        background: rgba(255, 255, 255, 1);
        margin-top: 15px;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);

        & > div:nth-child(1) {
            width: 275px;
            height: 17px;
            font-size: 18px;
            font-family: PingFang SC;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
            line-height: 24px;
            margin-top: 12px;
        }

        & > div:nth-child(2) {
            margin-top: 15px;
            display: flex;

            & > div {
                width: 168px;
                height: 134px;
            }

            & > div > div {
                font-size: 14.01px;
                line-height: 24.02px;
                text-align: left;
                font-family: PingFangSC-Regular;
            }

            & > img {
                width: 154px;
                height: 154px;
            }

            & > div > div:nth-child(1) {
                color: #666666;
                font-weight: bold;
            }

            & > div > div:nth-child(2) {
                color: #999999;
            }
        }

        & > div:nth-child(3) {
            text-align: left;
            margin-top: 26px;

            & > span {
                color: #1654B2;
                font-size: 27.1px;
                font-family: PingFangSC-Regular;
            }

            & > span:nth-child(3) {
                color: #333333;
                font-size: 15.81px;
            }
        }

        & > div:nth-child(4) {
            display: flex;

            & > div {
                width: 25%;
                margin-top: 10px;
            }

            & > div:not(:last-child) {
                border-right: 1px solid rgba(230, 231, 235, 1);
            }

            & > div > div:nth-child(1) {
                height: 16px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 500;
                color: rgba(102, 102, 102, 1);
                line-height: 24px;
            }

            & > div > div:nth-child(2) {
                height: 16px;
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
                line-height: 24px;
                margin-top: 3px;
            }

        }

        & > div:nth-child(5) {
            width: 100%;

            & > button {
                width: 210px;
                height: 50px;
                background: rgba(59, 139, 223, 1);
                border-radius: 8px;
            }
        }
    }
</style>
